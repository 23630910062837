<template lang="pug">
  .app-radio-button(:class="{ disabled }")
    .big-circle(
      @click="handleClick"
    )
      .small-circle(v-if="value")
      .indeterminate-circle(v-else-if="indeterminate")
</template>

<script>
  export default {
    props: {
      value: Boolean,
      disabled: {
        type: Boolean,
        default: false
      },
      indeterminate: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleClick() {
        !this.disabled && this.$emit("change", !this.value)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  $circle-side: 20px
  $small-circle-side: 10px
  $indeterminate-circle-side: 5px

  .app-radio-button

    .big-circle
      align-items: center
      background-color: $default-white
      border: 2px solid $default-purple
      border-radius: $circle-side
      cursor: pointer
      display: flex
      height: $circle-side
      justify-content: center
      width: $circle-side

      .indeterminate-circle,
      .small-circle
        background-color: $default-purple

      .indeterminate-circle
        border-radius: $indeterminate-circle-side
        height: $indeterminate-circle-side
        width: $indeterminate-circle-side

      .small-circle
        border-radius: $small-circle-side
        height: $small-circle-side
        width: $small-circle-side

    &.disabled
      .big-circle
        cursor: not-allowed
        border: 2px solid $default-gray

        .indeterminate-circle,
        .small-circle
          background-color: $default-gray
</style>
